
.aspect-ratio-icon {
  filter: invert(61%) sepia(8%) saturate(821%) hue-rotate(201deg) brightness(90%) contrast(85%)
}

/* .aspect-ratio-icon:hover {
  filter: invert(44%) sepia(6%) saturate(1578%) hue-rotate(201deg) brightness(95%) contrast(89%)
} */

.active-aspect-ratio-icon {
  filter: invert(28%) sepia(92%) saturate(2407%) hue-rotate(237deg) brightness(117%) contrast(101%)
}
