.video-controls {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.10) 20%, rgba(0, 0, 0, 0.5) 100%);
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
}

.volume-slider {
  &[type=range] {
    margin: 10px 0;
    width: 100%;
  }

  &[type=range]:focus {
    outline: none;
  }

  &[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    // box-shadow: 1px 1px 1px #000000;
    // background: #7B61FF;
    // background-color: line-gradient(90deg, #7B61FF 0%, #282823 100%);
    border-radius: 1px;
    border: 0px solid #010101;
  }

  &[type=range]::-webkit-slider-thumb {
    // box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 12px;
    width: 12px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
  }

  &[type=range]:focus::-webkit-slider-runnable-track {
    // background: #7B61FF;
  }

  &[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    // box-shadow: 1px 1px 1px #000000;
    // background: #282823;
    border-radius: 1px;
    border: 0px solid #010101;
  }

  &[type=range]::-moz-range-thumb {
    // box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 12px;
    width: 12px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
  }

  &[type=range]::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    // background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &[type=range]::-ms-fill-lower {
    // background: #7B61FF;
    border: 0px solid #010101;
    border-radius: 2px;
    // box-shadow: 1px 1px 1px #000000;
  }

  &[type=range]::-ms-fill-upper {
    // background: #282823;
    border: 0px solid #010101;
    border-radius: 2px;
    // box-shadow: 1px 1px 1px #000000;
  }

  &[type=range]::-ms-thumb {
    margin-top: 1px;
    // box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 12px;
    width: 12px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
  }

  &[type=range]:focus::-ms-fill-lower {
    // background: #7B61FF;
  }

  &[type=range]:focus::-ms-fill-upper {
    // background: #282823;
  }
}
